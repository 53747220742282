import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import App from "../helpers/app";
import Api from "../helpers/api";
import { take } from 'rxjs/internal/operators/take';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-permissoes-detalhes',
  templateUrl: './permissoes-detalhes.component.html',
  styleUrls: ['./permissoes-detalhes.component.scss']
})
export class PermissoesDetalhesComponent implements OnInit {
  apiCall = this.api.new().silent();


  constructor(private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private app: App,
    private api: Api,
   private spinner: NgxSpinnerService
  ) {
   }
   nomeCliente = '';
   permissoes;
   moduloSelecionado = null
   validade = null
   idCidade = null
   validadeIndeterminada = true
   permission = {
    id: null,
    id_modulo: null,
    data_validade: null,
    ativo: null
   }
   modulos = [
    { id: 1, nome: 'Sapiem' },
    { id: 2, nome: 'Simulador' }
   ]

   status = [
    { id: 1, nome: 'Ativo' },
    { id: 0, nome: 'Inativo' }
   ]

   async ngOnInit() {
    const self = this;
    this.route.queryParams.subscribe((params) => {
      try {
        if (!params.data) {
          this.permissoes = [];
          return;
        }
  
        const permissoesRecebidas = JSON.parse(params.data);
        this.idCidade = permissoesRecebidas.id;
        console.log("cidade", this.idCidade);
        // const listaPermissoes = Array.isArray(permissoesRecebidas) ? permissoesRecebidas : [permissoesRecebidas];
        

        this.loadData();

     /*   const hoje = new Date();
  
        this.permissoes = listaPermissoes.reduce((acc, permissao) => {
          const isSapiemAtivo = permissao.sapiem === 1 && this.validarData(permissao.data_validade_sapiem, hoje);
          if (permissao.sapiem === 1) {
            acc.push({
              id: permissao.id,
              municipio: permissao.municipio,
              modulo: 'Sapiem',
              data_validade: this.formatDate(permissao.data_validade_sapiem),
              ativo: isSapiemAtivo
            });
          }
  
          const isSimuladorAtivo = permissao.simulador === 1 && this.validarData(permissao.data_validade_simulador, hoje);
          if (permissao.simulador === 1) {
            acc.push({
              id: permissao.id,
              municipio: permissao.municipio,
              modulo: 'Simulador',
              data_validade: this.formatDate(permissao.data_validade_simulador),
              ativo: isSimuladorAtivo 
            });
          }
  
          return acc;
        }, []);
  
        console.log(this.permissoes);*/
      } catch (error) {
        console.error('Erro ao processar as permissões:', error);
        this.permissoes = [];
      }
    });
  }

  toggleValidadeIndeterminada() {
    if (this.validadeIndeterminada) {
      this.permission.data_validade = null; // Remove a data se estiver indeterminada
    }
  }

  async loadData() {

    const self = this

    self.spinner.show(undefined, {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });

    let permissoes = await this.apiCall
      .get(`permissoes/getPermissoes/${this.idCidade}`)
      .pipe(take(1))
      .toPromise();
    if (permissoes) {
      this.permissoes = permissoes;
      console.log('permissoes', this.permissoes);
    }

    self.spinner.hide();
  }
  
  private formatDate(date: string | Date): string {
    if (!date) {
      return "";
    }

    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Adiciona zero à esquerda, se necessário
    const day = d.getDate().toString().padStart(2, "0"); // Adiciona zero à esquerda, se necessário

    return `${day}-${month}-${year}`;
  }

  getExpirationDate(date: string | Date): string {
    if (!date) {
      return "Indeterminado";
    }

    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Adiciona zero à esquerda, se necessário
    const day = d.getDate().toString().padStart(2, "0"); // Adiciona zero à esquerda, se necessário

    return `${day}/${month}/${year}`;
  }
  abrirModal(content): void {

    this.permission.ativo = true
    this.permission.data_validade = null
    this.permission.id_modulo = null
    this.permission.id = null

    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  validarData(data: string | null, hoje: Date): boolean {
    if (!data) return false; // Se não tiver data, não está ativo
  
    const dataPermissao = new Date(data);
    return dataPermissao >= hoje; // Retorna true se a data for maior ou igual à de hoje
  }
  addPermission() {
    console.log('Adicionar nova permissão');
  }

  editPermission(permission, modal) {
    const self = this;

    if(permission.data_validade){
      permission.data_validade = this.formatDateToInput(permission.data_validade);
    }

    self.permission.id_modulo = permission.id_modulo;
    self.permission.id = permission.id;
    self.permission.data_validade = permission.data_validade;
    self.permission.ativo = permission.ativo;

    
    console.log('Editar permissão', self.permission);

    this.modalService.open(modal, { centered: true, size: 'lg' });

  }

  // Método para formatar a data para YYYY-MM-DD
formatDateToInput(dateString: string): string {
  const date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    return date.toISOString().split('T')[0]; // Converte para o formato YYYY-MM-DD
  }
  return ''; // Retorna string vazia caso a data seja inválida
}

  goBack(): void {
    this.router.navigate(['/page/permissoes']); 
  }

  async deletePermissao(id: number) {
    console.log(`Excluir permissão com ID: ${id}`);
    const self = this;
        
        if (
          await self.app.confirm(
            `Atenção`,
            `Você deseja mesmo deletar esta permissão?`,
            `Deletar`
          )
        ) {
          self.spinner.show(undefined, {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
      
          self.apiCall.delete(`permissoes/deletePermissao/${id}`).pipe(take(1)).subscribe(
            async (data: any) => {
              self.spinner.hide();
              
              if (data && data.success) {
                self.app.alert('Sucesso', 'Permissão excluída com sucesso!', 'success');
               await self.loadData(); 
               self.spinner.hide()
              } else {
                self.spinner.hide()
                self.app.alert('Erro', 'Não foi possível excluir a permissão. Tente novamente.', 'error');
              }
            },
            (error) => {
              self.spinner.hide();
              self.app.alert('Erro', 'Erro ao excluir permissão!', 'error');
              console.error('Erro ao excluir permissão:', error);
            }
          );
        }
  }

  async savePermission() {
    const self = this;
    console.log(this.permission.id ? 'Editar permissão' : 'Adicionar permissão', this.permission);
  
    // Validação dos campos obrigatórios
    if (!this.permission.id_modulo || (!this.permission.id && !this.idCidade)) {
      self.app.alert('Erro', 'Verifique todos os campos e tente novamente.', 'error');
      return;
    }
  
    // Construção do corpo da requisição
    let body = {
      id_cidade: this.idCidade, // Somente na criação
      id_modulo: this.permission.id_modulo,
      data_validade: this.permission.data_validade ? this.permission.data_validade : null,
      ativo: this.permission.ativo ?? true,
    };
  
    // Define se é criação ou edição
    const isEditing = !!this.permission.id;
    const request = isEditing
      ? self.apiCall.put(`permissoes/editPermissao/${this.permission.id}`, body)
      : self.apiCall.post(`permissoes/createPermissao/`, body);
  
    // Executa a requisição
    request.pipe(take(1)).subscribe(
      async (data: any) => {
        self.spinner.hide();
  
        if (data && data.success) {
          self.modalService.dismissAll();
          await self.loadData();
          await self.app.alert('Sucesso', `Permissão ${isEditing ? 'editada' : 'criada'} com sucesso!`, 'success');
        } else {
          self.app.alert('Erro', `Não foi possível ${isEditing ? 'editar' : 'criar'} a permissão. Tente novamente.`, 'error');
        }
      },
      (error) => {
        self.spinner.hide();
        self.app.alert('Erro', `Erro ao ${isEditing ? 'editar' : 'criar'} permissão!`, 'error');
        console.error(`Erro ao ${isEditing ? 'editar' : 'criar'} permissão:`, error);
      }
    );
  }
  

}
