import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import Me from "app/helpers/me";
import { take } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-sapiem-beneficios-detalhe",
  templateUrl: "./sapiem-beneficios-detalhe.component.html",
  styleUrls: ["./sapiem-beneficios-detalhe.component.scss"],
})
export class SapiemBeneficiosDetalheComponent implements OnInit {
  apiCall = this.api.new().silent();
  idBeneficio: any;
  informacoesBeneficioForm: FormGroup; // Definimos o FormGroup
  assentamentoForm: FormGroup;
  exercicioMagisterioForm: FormGroup;
  averbacaoForm: FormGroup;
  remuneracaoForm: FormGroup;
  basicInfoVisible = false;
  compRemuneracaoVisible = false;
  regAssentamentoVisible = false;
  exercMagisterioVisible = false;
  regAverbacaoVisible = false;
  validateAnothers = false;
  nomeBeneficio = null

  // Propriedades para controlar a validade dos dados
  basicInfoValid = false;
  compRemuneracaoValid = false;
  regAssentamentoValid = false;
  exercMagisterioValid = false;
  regAverbacaoValid = false;
  nomeServidor: any;
  beneficio: any;
  generoBeneficio: any;
  especieBeneficio: any;
  modalidadeBeneficio: any;
  regimeJuridico: any;
  tipoLegislacao: any;
  tipoEscolha: any;
  tipoAssentamento: any;
  documentos: any;
  assentamentos = null;
  origemEscola = null;
  tipoAtividade = null;
  exerciciosMagisterio = null;
  averbacoes = null;
  remuneracoes = null;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private me: Me,
    private app: App,
    private api: Api,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {}

  async ngOnInit() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    // Inicializar o FormGroup com todos os campos do seu formulário
    this.informacoesBeneficioForm = this.fb.group({
      id: [null], // Adicionado para identificar edição
      // Informações Básicas
      generoBeneficio: ["", Validators.required],
      especieBeneficio: ["", Validators.required],
      modalidadeBeneficio: ["", Validators.required],
      matricula: ["", Validators.required],
      identificacaoFuncional: ["", Validators.required],
      cargo: ["", Validators.required],
      regimeJuridico: ["", Validators.required],
      cargaHoraria: ["", Validators.required],
      padrao: ["", Validators.required],
      nivel: ["", Validators.required],
      classe: ["", Validators.required],
      outros: [""],
      escolaridade: ["", Validators.required],
      observacoes: [""],
      tituloEleitor: ["", Validators.required],

      // Informações Legislativas
      codigoLegislativo: ["", Validators.required],
      cpfResponsavel: ["", Validators.required],
      cpfSignatario: ["", Validators.required],
      tipoLegislacao: ["", Validators.required],
      numeroLegislacao: ["", Validators.required],
      anoLegislacao: ["", Validators.required],
      dataInicio: ["", Validators.required],

      // Informações Salariais
      mediaSalarial: ["", Validators.required],
      dataDemonstrativo: ["", Validators.required],
      assinadoPor: ["", Validators.required],

      // Informações Judiciais
      tipoAto: ["", Validators.required],
      numeroAto: ["", Validators.required],
      anoAto: ["", Validators.required],
      valorProvento: ["", Validators.required],
      vantagens: [""],
      descricaoOrgao: ["", Validators.required],
      complementoSalarial: ["", Validators.required],

      //Informações Aposentaoria
      fundamentoAposentadoria: ["", Validators.required],
      dataRequerimento: ["", Validators.required],
    });

    this.assentamentoForm = this.fb.group({
      id: [null], // Adicionado para identificar edição
      tipo_assentamento: ["", Validators.required], //
      data: ["", Validators.required], //
      tipo_legislacao: ["", Validators.required], //
      ano_legislacao: [
        "",
        [
          Validators.required,
          Validators.min(1900),
          Validators.max(new Date().getFullYear()),
        ],
      ], //
      numero_ato: ["", Validators.required], //
      numero_legislacao: ["", Validators.required], //
      data_publicacao: ["", Validators.required], //
      numero_dias: ["", Validators.required], //
      dias_convertidos_em_dobro: [Validators.required], //
      descricao: ["", Validators.maxLength(500)], //
      data_inicio_periodo_aquisitivo: ["", Validators.required], //
      data_fim_periodo_aquisitivo: ["", Validators.required], //
    });

    this.exercicioMagisterioForm = this.fb.group({
      id: [null], // Identificador para edição
      nome_escola: ["", Validators.required],
      origem_escola: ["", Validators.required], // Select
      tipo_atividade: ["", Validators.required], // Select
      numero_certidao: ["", Validators.required],
      data_inicio: ["", Validators.required],
      data_fim: ["", Validators.required],
      data_requerimento: ["", Validators.required],
      fundamentacao: ["", Validators.required], // Select
      descricao_atividade: ["", Validators.required],
    });

    this.averbacaoForm = this.fb.group({
      id: [null],
      descricao: ["", Validators.required],
      data_inicio: ["", Validators.required],
      data_fim: ["", Validators.required],
      dias_averbados: ["", Validators.required],
      numero_documento: ["", Validators.required],
      servico_publico: ["", Validators.required],
      carreira: ["", Validators.required],
      vantagens: ["", Validators.required],
      contribuicao_inss: ["", Validators.required],
    });

    this.remuneracaoForm = this.fb.group({
      id: [null], // Identificador para edição
      descricao: ["", [Validators.required, Validators.maxLength(200)]],
      valor: [null, [Validators.required]], // Aceita valores decimais
      tipo_legislacao: [null, Validators.required], // Select
      numero_legislacao: ["", Validators.required],
      ano_legislacao: [
        null,
        [
          Validators.required,
          Validators.min(1900),
          Validators.max(new Date().getFullYear()),
        ],
      ],
      artigos: ["", [Validators.maxLength(500)]], // Texto
    });

    this.route.queryParams.subscribe((parametros) => {
      if (parametros["idBeneficio"]) {
        this.idBeneficio = parametros["idBeneficio"];
      }
      if (parametros["nomeServidor"]) {
        this.nomeServidor = parametros["nomeServidor"];
      }

      if (parametros["nomeBeneficio"]) {
        this.nomeBeneficio = parametros["nomeBeneficio"];
      }
    });

    await this.loadReferencesData();
    await this.loadData();

    console.log(this.idBeneficio);

    self.spinner.hide();
  }

  async loadData() {
    const self = this;

    self.spinner.hide();

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let beneficio = await this.apiCall
      .get(`sapiem/getBeneficio/${this.idBeneficio}`)
      .pipe(take(1))
      .toPromise();
    if (beneficio) {
      this.beneficio = beneficio;
      const documento = await this.apiCall
        .get(`servidor/documentos?id_servidor=${this.beneficio.id_servidor}`)
        .pipe(take(1))
        .toPromise();
      if (documento) this.documentos = documento;
      await this.validateData(beneficio, "basicInfo");
      this.populateBeneficioForm();
    }

    let assentamentos = await this.apiCall
      .get(`sapiem/assentamento/${this.idBeneficio}`)
      .pipe(take(1))
      .toPromise();
    if (assentamentos) {
      this.assentamentos = assentamentos;
      await this.validateData(assentamentos, "assentamento");
      console.log("Assentamentos:", assentamentos);
    }

    let exerciciosMagisterio = await this.apiCall
      .get(`sapiem/magisterio/${this.idBeneficio}`)
      .pipe(take(1))
      .toPromise();
    console.log("Exercícios de magistério:", exerciciosMagisterio);
    if (exerciciosMagisterio) {
      this.exerciciosMagisterio = exerciciosMagisterio;
      await this.validateData(exerciciosMagisterio, "exercicioMagisterio");
    }

    let averbacoes = await this.apiCall
      .get(`sapiem/averbacao/${this.idBeneficio}`)
      .pipe(take(1))
      .toPromise();
    console.log("Averbacoes:", averbacoes);
    if (averbacoes) {
      this.averbacoes = averbacoes;
      await this.validateData(averbacoes, "averbacao");
    }

    let remuneracoes = await this.apiCall
      .get(`sapiem/remuneracao/${this.idBeneficio}`)
      .pipe(take(1))
      .toPromise();
    console.log("remuneracoes:", remuneracoes);
    if (remuneracoes) {
      this.remuneracoes = remuneracoes;
      await this.validateData(remuneracoes, "remuneracao");
    }

    console.log(this.beneficio);

    await this.getMediaSalarial();

    self.spinner.hide();

  }

  async validateData(data: any, model: string): Promise<void> {
    let validateData = false;

    console.log("this.beneficio:", model, data);

    // Valida se o objeto ou array contém dados válidos
    if (data) {
      if (model === "basicInfo") {
        // Validação para basicInfo como objeto
        validateData = Object.values(data).every(
          (value) => value !== null && value !== undefined && value !== ""
        );
      } else if (Array.isArray(data) && data.length > 0) {
        // Validação para arrays (assentamento, averbacao, etc.)
        validateData = data.some(
          (item: any) =>
            item &&
            Object.values(item).every(
              (value) => value !== null && value !== undefined && value !== ""
            )
        );
      }
    }

    // Define a flag correspondente ao modelo
    switch (model) {
      case "assentamento":
        this.regAssentamentoValid = validateData;
        break;

      case "exercicioMagisterio":
        this.exercMagisterioValid =
          this.beneficio.especie === 22 ? validateData : true;
        break;

      case "averbacao":
        this.regAverbacaoValid = validateData;
        break;

      case "remuneracao":
        this.compRemuneracaoValid = validateData;
        break;

      case "basicInfo":
        this.basicInfoValid = validateData;
        break;

      default:
        console.warn("Modelo não reconhecido:", model);
    }
  }

  async getMediaSalarial() {
    const self = this;

    if(this.beneficio.media_salarial && typeof this.beneficio.media_salarial === 'number'){

      this.informacoesBeneficioForm
        .get("mediaSalarial")
        .setValue(this.beneficio.media_salarial.toFixed(2)
        .toString()
        .replace(".", ","))
    
    }else{
      
    let movimentosMensais = await this.apiCall
      .get(
        "servidor/movimentos/movimentacoesmensais/recuperar?id_servidor=" +
          self.beneficio.id_servidor
      )
      .pipe(take(1))
      .toPromise();

    if (movimentosMensais) {
      // Calcula a média do campo 'valor'
      const total = movimentosMensais.reduce(
        (sum, item) => sum + item.valor,
        0
      );
      const media = total / movimentosMensais.length;

      // Atualiza o campo mediaSalarial no formulário
      this.informacoesBeneficioForm
        .get("mediaSalarial")
        .setValue(media);

      console.log("Média calculada e atualizada:", media);
    }
  }
}

  async populateBeneficioForm() {
    const self = this;
    this.informacoesBeneficioForm.patchValue({
      id: this.beneficio.id,
      generoBeneficio: this.beneficio.genero_beneficio,
      especieBeneficio: this.beneficio.especie,
      modalidadeBeneficio: this.beneficio.modalidade,
      matricula: this.beneficio.matricula,
      identificacaoFuncional: this.beneficio.identificacao_funcional,
      cargo: this.beneficio.cargo,
      regimeJuridico: this.beneficio.regime_juridico,
      cargaHoraria: this.beneficio.carga_horaria,
      padrao: this.beneficio.padrao,
      nivel: this.beneficio.nivel,
      classe: this.beneficio.classe,
      outros: this.beneficio.outros,
      escolaridade: this.beneficio.escolaridade,
      observacoes: this.beneficio.observacoes,
      cpfResponsavel: this.beneficio.cpf_responsavel,
      codigoLegislativo: this.beneficio.codigo,
      cpfSignatario: this.beneficio.cpf_signatario,
      tipoLegislacao: this.beneficio.tipo_legislacao,
      numeroLegislacao: this.beneficio.numero_legislacao,
      anoLegislacao: this.beneficio.ano_legislacao,
      dataDemonstrativo: this.formatDate(this.beneficio.data_demonstrativo),
      dataInicio: this.formatDate(this.beneficio.data_inicio),
      tipoAto: this.beneficio.tipo_ato,
      numeroAto: this.beneficio.numero_ato,
      anoAto: this.beneficio.ano_ato,
      valorProvento: this.beneficio.valor_provento.toFixed(2)
      .toString()
      .replace(".", ","),
      vantagens: this.beneficio.vantagens,
      descricaoOrgao: this.beneficio.descricao_orgao_custeador,
      mediaSalarial:
        this.beneficio.media_salarial === "NaN"
          ? ""
          : this.beneficio.media_salarial,
      assinadoPor: this.beneficio.assinado_por,
      complementoSalarial: this.beneficio.complemento_salarial,
      tituloEleitor:
        this.documentos && this.documentos.titulo_eleitor
          ? this.documentos.titulo_eleitor
          : "",
      fundamentoAposentadoria: this.beneficio.fundamento_aposentadoria_ok,
      dataRequerimento: this.formatDate(this.beneficio.dt_requerimento),
    });
  }

  toggleBasicInfo() {
    this.basicInfoVisible = !this.basicInfoVisible;
  }

  toggleCompRemuneracao() {
    this.compRemuneracaoVisible = !this.compRemuneracaoVisible;
  }

  toggleRegAssentamento() {
    this.regAssentamentoVisible = !this.regAssentamentoVisible;
  }

  toggleExercMagisterio() {
    this.exercMagisterioVisible = !this.exercMagisterioVisible;
  }

  toggleRegAverbacao() {
    this.regAverbacaoVisible = !this.regAverbacaoVisible;
  }

  voltar() {
    window.history.back();
  }

  async loadReferencesData() {
    const self = this;

    try {
      const generoBeneficio = await this.apiCall
        .get("sapiem/getGeneroBeneficio")
        .pipe(take(1))
        .toPromise();
      if (generoBeneficio) this.generoBeneficio = generoBeneficio;

      console.log("Genero beneficio:", generoBeneficio);

      const especieBeneficio = await this.apiCall
        .get("sapiem/getEspecie")
        .pipe(take(1))
        .toPromise();
      if (especieBeneficio) this.especieBeneficio = especieBeneficio;

      const modalidadeBeneficio = await this.apiCall
        .get("sapiem/getMobilidade")
        .pipe(take(1))
        .toPromise();
      if (modalidadeBeneficio) this.modalidadeBeneficio = modalidadeBeneficio;

      const regimeJuridico = await this.apiCall
        .get("sapiem/getRegimeJuridico")
        .pipe(take(1))
        .toPromise();
      if (regimeJuridico) this.regimeJuridico = regimeJuridico;

      const tipoLegislacao = await this.apiCall
        .get("sapiem/getTipoLegislacao")
        .pipe(take(1))
        .toPromise();
      if (tipoLegislacao) this.tipoLegislacao = tipoLegislacao;

      const sn = await this.apiCall
        .get("sapiem/getSn")
        .pipe(take(1))
        .toPromise();
      if (sn) this.tipoEscolha = sn;

      const tipoAssentamento = await this.apiCall
        .get("sapiem/getTipoAssentamento")
        .pipe(take(1))
        .toPromise();
      if (tipoAssentamento) this.tipoAssentamento = tipoAssentamento;
      console.log("Tipo assentamento:", tipoAssentamento);

      const origemEscola = await this.apiCall
        .get("sapiem/getOrigemEscola")
        .pipe(take(1))
        .toPromise();
      if (origemEscola) this.origemEscola = origemEscola;

      const tipoAtividade = await this.apiCall
        .get("sapiem/getTipoAtividade")
        .pipe(take(1))
        .toPromise();
      if (tipoAtividade) this.tipoAtividade = tipoAtividade;
    } catch (error) {
      console.error("Erro ao carregar os dados de referência:", error);
    }
  }

  openModal(modal) {
    this.remuneracaoForm.reset();
    this.averbacaoForm.reset();
    this.exercicioMagisterioForm.reset();
    this.assentamentoForm.reset();
    this.modalService.open(modal, { size: "lg" });
  }

  onSubmitAssentamento() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formValues = this.assentamentoForm.value;

    formValues.id_beneficio = this.idBeneficio;

    console.log("Formulário de assentamento:", formValues);

    // Verificar se é edição ou criação
    const isEdit = !!formValues.id;
    const apiEndpoint = isEdit
      ? `sapiem/updateAssentamento/${formValues.id}` // Edição (PUT)
      : `sapiem/createAssentamento/`; // Criação (POST)

    const apiCall = isEdit
      ? self.apiCall.put(apiEndpoint, formValues) // Usando PUT para edição
      : self.apiCall.post(apiEndpoint, formValues); // Usando POST para criação

    apiCall.pipe(take(1)).subscribe(
      async (data: any) => {
        self.spinner.hide();

        if (data) {
          self.modalService.dismissAll();
          await self.app.alert(
            "Sucesso",
            "Assentamento cadastrado com sucesso!",
            "success"
          );
          self.loadData();
        } else {
          self.app.alert(
            "Erro",
            "Não foi possível excluir o benefício. Tente novamente.",
            "error"
          );
        }
      },
      (error) => {
        self.spinner.hide();
        self.app.alert("Erro", "Erro ao excluir benefício!", "error");
        console.error("Erro ao excluir benefício:", error);
      }
    );
  }

  getTipoAssentamentoNome(id) {
    console.log("ID:", id);
    let label = this.tipoAssentamento.find((tipo) => tipo.id == id);
    if (label) {
      return label.descricao;
    } else {
      return "";
    }
  }

  getLegislacaoNome(id) {
    console.log("ID:", id);
    let label = this.tipoLegislacao.find((tipo) => tipo.id == id);
    if (label) {
      return label.nome;
    } else {
      return "";
    }
  }

  async deleteAssentamento(id) {
    const self = this;

    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este assentamento?`,
        `Deletar`
      )
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });

      self.apiCall
        .delete(`sapiem/assentamento/${id}`)
        .pipe(take(1))
        .subscribe(
          async (data: any) => {
            self.spinner.hide();

            if (data) {
              await self.app.alert(
                "Sucesso",
                "Assentamento excluído com sucesso!",
                "success"
              );
              self.loadData();
            } else {
              self.app.alert(
                "Erro",
                "Não foi possível excluir o assentamento. Tente novamente.",
                "error"
              );
            }
          },
          (error) => {
            self.spinner.hide();
            self.app.alert("Erro ao excluir assentamento!", "error");
          }
        );
    }
  }

  editAssentamento(assentamento: any, modal) {
    console.log("Assentamento:", assentamento);
    this.assentamentoForm.patchValue({
      id: assentamento.id,
      tipo_assentamento: assentamento.tipo_assentamento,
      data: this.formatDate(assentamento.data),
      tipo_legislacao: assentamento.tipo_legislacao,
      ano_legislacao: assentamento.ano_legislacao,
      numero_ato: assentamento.numero_ato,
      data_publicacao: this.formatDate(assentamento.data_publicacao),
      numero_dias: assentamento.numero_dias,
      dias_convertidos_em_dobro: assentamento.dias_convertidos_em_dobro,
      descricao: assentamento.descricao,
      data_inicio_periodo_aquisitivo: this.formatDate(
        assentamento.data_inicio_periodo_aquisitivo
      ),
      data_fim_periodo_aquisitivo: this.formatDate(
        assentamento.data_fim_periodo_aquisitivo
      ),
    });

    this.modalService.open(modal, { size: "lg", backdrop: "static" });
  }

  private formatDate(date: string | Date): string {
    if (!date) {
      return "";
    }

    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Adiciona zero à esquerda, se necessário
    const day = d.getDate().toString().padStart(2, "0"); // Adiciona zero à esquerda, se necessário

    return `${year}-${month}-${day}`;
  }

  onSubmitExercicioMagisterio() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formValues = this.exercicioMagisterioForm.value;

    formValues.id_beneficio = this.idBeneficio;

    // Verificar se é criação ou edição
    const isEdit = !!formValues.id;
    const apiEndpoint = isEdit
      ? `sapiem/updateMagisterio/${formValues.id}` // Edição
      : `sapiem/createMagisterio/`; // Criação

    const apiCall = isEdit
      ? self.apiCall.put(apiEndpoint, formValues) // PUT para edição
      : self.apiCall.post(apiEndpoint, formValues); // POST para criação

    apiCall.pipe(take(1)).subscribe(
      async (data: any) => {
        self.spinner.hide();
        if (data) {
          self.modalService.dismissAll();
          await self.app.alert(
            "Sucesso",
            isEdit
              ? "Exercício atualizado com sucesso!"
              : "Exercício criado com sucesso!",
            "success"
          );
          self.loadData(); // Recarregar lista
        } else {
          self.app.alert(
            "Erro",
            "Não foi possível salvar o exercício. Tente novamente.",
            "error"
          );
        }
      },
      (error) => {
        self.spinner.hide();
        self.app.alert(
          "Erro",
          `Erro ao ${isEdit ? "atualizar" : "criar"} exercício!`,
          "error"
        );
        console.error(
          `Erro ao ${isEdit ? "atualizar" : "criar"} exercício:`,
          error
        );
      }
    );
  }

  editExercicioMagisterio(exercicio: any, modal) {
    this.exercicioMagisterioForm.patchValue({
      id: exercicio.id,
      nome_escola: exercicio.nome_escola,
      origem_escola: exercicio.origem_escola, // Adicionado
      tipo_atividade: exercicio.tipo_atividade, // Adicionado
      numero_certidao: exercicio.numero_certidao, // Adicionado
      data_inicio: this.formatDate(exercicio.data_inicio),
      data_fim: this.formatDate(exercicio.data_fim),
      data_requerimento: this.formatDate(exercicio.data_requerimento), // Adicionado
      fundamentacao: exercicio.fundamentacao, // Adicionado
      descricao_atividade: exercicio.descricao_atividade,
    });

    this.modalService.open(modal, { size: "lg", backdrop: "static" });
  }

  async deleteExercicioMagisterio(id: number) {
    const self = this
    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este exercício?`,
        `Deletar`
      )
    ) {

      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });


      this.apiCall
        .delete(`sapiem/deleteExercicioMagisterio/${id}`)
        .pipe(take(1))
        .subscribe(
          async () => {
            this.spinner.hide();
            await this.app.alert(
              "Sucesso",
              "Exercício excluído com sucesso!",
              "success"
            );
            this.loadData(); // Recarregar lista
          },
          (error) => {
            this.spinner.hide();
            this.app.alert("Erro", "Erro ao excluir exercício!", "error");
            console.error("Erro ao excluir exercício:", error);
          }
        );
    }
  }

  onSubmitAverbacao() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formValues = this.averbacaoForm.value;

    console.log("Formulário de averbação:", formValues);

    formValues.id_beneficio = this.idBeneficio;

    // Verificar se é criação ou edição
    const isEdit = !!formValues.id;
    const apiEndpoint = isEdit
      ? `sapiem/updateAverbacao/${formValues.id}` // Edição
      : `sapiem/createAverbacao`; // Criação

    const apiCall = isEdit
      ? self.apiCall.put(apiEndpoint, formValues) // PUT para edição
      : self.apiCall.post(apiEndpoint, formValues); // POST para criação

    apiCall.pipe(take(1)).subscribe(
      async (data: any) => {
        self.spinner.hide();
        if (data) {
          self.modalService.dismissAll();
          await self.app.alert(
            "Sucesso",
            isEdit
              ? "Averbação atualizada com sucesso!"
              : "Averbação criada com sucesso!",
            "success"
          );
          self.loadData(); // Recarregar lista
        } else {
          self.app.alert(
            "Erro",
            "Não foi possível salvar a averbação. Tente novamente.",
            "error"
          );
        }
      },
      (error) => {
        self.spinner.hide();
        self.app.alert(
          "Erro",
          `Erro ao ${isEdit ? "atualizar" : "criar"} averbação!`,
          "error"
        );
        console.error(
          `Erro ao ${isEdit ? "atualizar" : "criar"} averbação:`,
          error
        );
      }
    );
  }

  editAverbacao(averbacao: any, modal) {
    this.averbacaoForm.patchValue({
      id: averbacao.id,
      descricao: averbacao.descricao,
      data_inicio: this.formatDate(averbacao.data_inicio),
      data_fim: this.formatDate(averbacao.data_fim),
      dias_averbados: averbacao.dias_averbados,
      numero_documento: averbacao.numero_documento,
      servico_publico: averbacao.servico_publico,
      carreira: averbacao.carreira,
      vantagens: averbacao.vantagens,
      contribuicao_inss: averbacao.contribuicao_inss,
    });
    this.modalService.open(modal, { size: "lg", backdrop: "static" });
  }

  async deleteAverbacao(id: number) {

    const self = this

    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar esta averbação?`,
        `Deletar`
      )
    ) {

      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });

      this.apiCall
        .delete(`sapiem/averbacao/${id}`)
        .pipe(take(1))
        .subscribe(
          async () => {
            this.spinner.hide();
            await this.app.alert(
              "Sucesso",
              "Averbação excluída com sucesso!",
              "success"
            );
            this.loadData(); // Recarregar lista
          },
          (error) => {
            this.spinner.hide();
            this.app.alert("Erro", "Erro ao excluir averbação!", "error");
            console.error("Erro ao excluir averbação:", error);
          }
        );
      }
    }

  formatToBRLCurrency(value) {
    if (typeof value !== "number") {
      throw new Error("O valor fornecido deve ser um número.");
    }

    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  editRemuneracao(remuneracao: any, modal) {
    this.remuneracaoForm.patchValue({
      id: remuneracao.id,
      descricao: remuneracao.descricao,
      valor: remuneracao.valor.toFixed(2)
      .toString()
      .replace(".", ","),
      tipo_legislacao: remuneracao.tipo_legislacao,
      numero_legislacao: remuneracao.numero_legislacao,
      ano_legislacao: remuneracao.ano_legislacao,
      artigos: remuneracao.artigos,
    });

    this.modalService.open(modal, { size: "lg", backdrop: "static" });
  }

  async deleteRemuneracao(id) {
    const self = this;

    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar esta remuneração?`,
        `Deletar`
      )
    ) {

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    self.apiCall
      .delete(`sapiem/remuneracao/${id}`)
      .pipe(take(1))
      .subscribe(
        async (data: any) => {
          self.spinner.hide();

          if (data) {
            await self.app.alert(
              "Sucesso",
              "Remuneração excluída com sucesso!",
              "success"
            );
            self.loadData();
          } else {
            self.app.alert(
              "Erro",
              "Não foi possível excluir a remuneração. Tente novamente.",
              "error"
            );
          }
        },
        (error) => {
          self.spinner.hide();
          self.app.alert("Erro ao excluir remuneração!", "error");
        }
      );
    }
  }

  onSubmitRemuneracao() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formValues = this.remuneracaoForm.value;

    formValues.id_beneficio = this.idBeneficio;

    console.log("Formulário de remuneração:", formValues.valor);

    /* if (typeof formValues.valor === 'number') {
      formValues.valor = formValues.valor / 100;
    } else if (typeof formValues.valor === 'string') {
      formValues.valor = parseFloat(
        formValues.valor.replace(/[R$.\s]/g, '').replace(',', '.')
      ) / 100;
    }*/

    formValues.valor = formValues.valor
      .toString()
      .replace(".", "")
      .replace(",", ".");

    console.log("Valor formatado:", formValues.valor);

    // Verificar se é criação ou edição
    const isEdit = !!formValues.id;
    const apiEndpoint = isEdit
      ? `sapiem/updateRemuneracao/${formValues.id}` // Edição
      : `sapiem/createRemuneracao`; // Criação

    const apiCall = isEdit
      ? self.apiCall.put(apiEndpoint, formValues) // PUT para edição
      : self.apiCall.post(apiEndpoint, formValues); // POST para criação

    apiCall.pipe(take(1)).subscribe(
      async (data: any) => {
        self.spinner.hide();
        if (data) {
          self.modalService.dismissAll();
          await self.app.alert(
            "Sucesso",
            isEdit
              ? "Remuneração atualizada com sucesso!"
              : "Remuneração criada com sucesso!",
            "success"
          );
          self.loadData(); // Recarregar lista
        } else {
          self.app.alert(
            "Erro",
            "Não foi possível salvar a remuneração. Tente novamente.",
            "error"
          );
        }
      },
      (error) => {
        self.spinner.hide();
        self.app.alert(
          "Erro",
          `Erro ao ${isEdit ? "atualizar" : "criar"} remuneração!`,
          "error"
        );
        console.error(
          `Erro ao ${isEdit ? "atualizar" : "criar"} remuneração:`,
          error
        );
      }
    );
  }

  async onSubmitBaseBenefit() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formValues = this.informacoesBeneficioForm.value;

    console.log("Formulário de benefício:", formValues);

    formValues.mediaSalarial = formValues.mediaSalarial
    .toString()
    .replace(".", "")
    .replace(",", ".");

    formValues.valorProvento = formValues.valorProvento
    .toString()
    .replace(".", "")
    .replace(",", ".");



    // Crie um objeto de benefício a partir dos valores do formulário, ajustando os nomes

    const beneficio = this.setBenefitToSend(formValues);

    console.log("Benefício a ser enviado:", beneficio);

    self.apiCall
      .put(`sapiem/updateBeneficio/${formValues.id}`, beneficio)
      .pipe(take(1))
      .subscribe(
        async (data: any) => {
          self.spinner.hide();

          if (data && data.id) {
            self.modalService.dismissAll();
            await self.app.alert(
              "Sucesso",
              "Benefício atualizado com sucesso!",
              "success"
            );
            self.loadData();
          } else {
            self.app.alert(
              "Erro",
              "Não foi possível editar o benefício. Tente novamente.",
              "error"
            );
          }
        },
        (error) => {
          self.spinner.hide();
          self.app.alert("Erro", "Erro ao excluir benefício!", "error");
          console.error("Erro ao excluir benefício:", error);
        }
      );

    // Agora você pode enviar o objeto `beneficio` para o backend
    console.log("Objeto benefício pronto para envio:", beneficio);
  }

  setBenefitToSend(formValues) {
    return {
      genero_beneficio: formValues.generoBeneficio,
      especie: formValues.especieBeneficio,
      modalidade: formValues.modalidadeBeneficio,
      matricula: formValues.matricula,
      identificacao_funcional: formValues.identificacaoFuncional,
      cargo: formValues.cargo,
      regime_juridico: formValues.regimeJuridico,
      carga_horaria: formValues.cargaHoraria,
      padrao: formValues.padrao,
      nivel: formValues.nivel,
      classe: formValues.classe,
      outros: formValues.outros,
      escolaridade: formValues.escolaridade,
      observacoes: formValues.observacoes,
      id_servidor: this.beneficio.id_servidor,
      codigo: formValues.codigoLegislativo,
      cpf_responsavel: formValues.cpfResponsavel,
      cpf_signatario: formValues.cpfSignatario,
      tipo_legislacao: formValues.tipoLegislacao,
      numero_legislacao: formValues.numeroLegislacao,
      ano_legislacao: formValues.anoLegislacao,
      data_inicio: formValues.dataInicio,
      tipo_ato: formValues.tipoAto,
      numero_ato: formValues.numeroAto,
      ano_ato: formValues.anoAto,
      valor_provento: formValues.valorProvento,
      complemento_salarial: formValues.complementoSalarial,
      vantagens: formValues.vantagens,
      descricao_orgao_custeador: formValues.descricaoOrgao,
      media_salarial: formValues.mediaSalarial,
      data_demonstrativo: formValues.dataDemonstrativo,
      assinado_por: formValues.assinadoPor,
      titulo_eleitor: formValues.tituloEleitor,
      fundamento_aposentadoria_ok: formValues.fundamentoAposentadoria,
      dt_requerimento: formValues.dataRequerimento,
    };
  }

  onCancelRemuneracao(modal: any): void {
    this.remuneracaoForm.reset(); // Limpa o formulário
    modal.dismiss(); // Fecha a modal
  }

  onCancelAverbacao(modal: any): void {
    this.averbacaoForm.reset(); // Limpa o formulário
    modal.dismiss(); // Fecha a modal
  }

  onCancelAssentamento(modal: any): void {
    this.assentamentoForm.reset();
    modal.close();
  }

  onCancelExercicioMagisterio(modal: any): void {
    this.exercicioMagisterioForm.reset();
    modal.close();
  }

  previousPage() {
    window.history.back();
  }
}
