import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import Me from 'app/helpers/me';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-permissoes',
  templateUrl: './permissoes.component.html',
  styleUrls: ['./permissoes.component.scss']
})
export class PermissoesComponent implements OnInit {

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private http: HttpClient,
    private api: Api
  ) { }
  apiCall = this.api.new().silent();
  page = 1;
  pageSize = 10;
  nomeCliente;
  sortColumn: string = '';
  sortDirection: string = 'asc'; 
  cidadesFiltered = [];
  cidades = [];

  async ngOnInit() {
    this.spinner.show();
    try {
      this.cidades = await this.apiCall.get("permissoes/getCidades").pipe(take(1)).toPromise();
      console.log("Dados recebidos da API:", this.cidades);
      this.cidadesFiltered = [...this.cidades]; 
    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
    } finally {
      this.spinner.hide(); 
    }
  }

  gotoDetails(obj: any) {
    const serializedData = JSON.stringify(obj);
    console.log("Dados serializados:", obj);
    this.router.navigate(['/page/permissoes-detalhes'], { queryParams: { data: serializedData } });
  }
  applyFilters() {
    // this.cidadesFiltered = this.servidores.filter(servidor => {
    //   const matchesNome = this.servidor.nome ? servidor.nome.toLowerCase().includes(this.servidor.nome.toLowerCase()) : true;
    //   const matchesCpf = this.servidor.cpf ? servidor.cpf.includes(this.servidor.cpf) : true;
    //   const matchesMatricula = this.servidor.matricula ? servidor.matricula.includes(this.servidor.matricula) : true;
    //   const matchesStatus = this.servidor.status ? servidor.status === this.servidor.status : true;
  
    //   return matchesNome && matchesCpf && matchesMatricula && matchesStatus;
    // });
  }

  clearFilters() {
    // Reseta os campos do objeto de filtro   
  
    // Restaura a lista filtrada com todos os servidores
    this.cidadesFiltered = [...this.cidades];
  
    // Reseta a página para a primeira
    this.page = 1;
  }

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
  
    this.cidadesFiltered.sort((a, b) => {
      let valueA, valueB;
  
      if (column === 'matricula') {
        valueA = a.status == 2 || a.status == 3 ? a.matricula_beneficiario : a.matricula;
        valueB = b.status == 2 || b.status == 3 ? b.matricula_beneficiario : b.matricula;
      } else if (column === 'status') {
        // valueA = this.classificaStatus(a.status);
        // valueB = this.classificaStatus(b.status);
      } else {
        valueA = a[column]?.toString().toLowerCase() || '';
        valueB = b[column]?.toString().toLowerCase() || '';
      }
  
      if (this.sortDirection === 'asc') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });
  }

}
