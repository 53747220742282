import {LOCALE_ID, NgModule} from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { DeviceDetectorModule } from 'ngx-device-detector';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import Api from "./helpers/api";
import App from "./helpers/app";
import ApiSimulador from "./helpers/apiSimulador";
import Me from "./helpers/me";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from "angularx-social-login";
import {DragulaModule, DragulaService} from "ng2-dragula";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ArchwizardModule} from "angular-archwizard";
import {CustomFormsModule} from "ngx-custom-validators";
import {UiSwitchModule} from "ngx-ui-switch";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {YouTubePlayerModule} from "@angular/youtube-player";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {registerLocaleData} from "@angular/common";
import pt from '@angular/common/locales/pt';
import {InternationalPhoneNumberModule} from "ng-phone-number";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {CreditCardDirectivesModule} from "angular-cc-library";
import { AdminHomeComponent } from './admin-home/admin-home.component';
import {JwtInterceptor} from "./helpers/jwt.interceptor";
import { ServidoresComponent } from './servidores/servidores.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ServidorComponent } from './servidor/servidor.component';
import { EnderecoComponent } from './endereco/endereco.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { DadosbancariosComponent } from './dadosbancarios/dadosbancarios.component';
import { AnexosComponent } from './anexos/anexos.component';
import { ContatoComponent } from './contato/contato.component';
import { DependentesComponent } from './dependentes/dependentes.component';
import { MovimentosComponent } from './movimentos/movimentos.component';
import { TemposComponent } from './tempos/tempos.component';
import { RepresentanteComponent } from './representante/representante.component';
import { ObitoComponent } from './obito/obito.component';
import { UsuariosMunicipioComponent } from './usuarios-municipio/usuarios-municipio.component';
import { OrgaosComponent } from './orgaos/orgaos.component';
import { RelatoriosMunicipioComponent } from './relatorios-municipio/relatorios-municipio.component';
import { RelatoriosAdminComponent } from './relatorios-admin/relatorios-admin.component';
import { DadospessoaisComponent } from './dadospessoais/dadospessoais.component';
import {NgxPrintModule} from 'ngx-print';
import {WebcamModule} from 'ngx-webcam';
import { ServidorHomeComponent } from './servidor-home/servidor-home.component';
import { RelatoriosBaseComponent } from './relatorios-base/relatorios-base.component';
import { RelatoriosPlanilhaComponent } from './relatorios-planilha/relatorios-planilha.component';
import { SimuladorAposentadoriaComponent } from './simulador-aposentadoria/simulador-aposentadoria.component';
import { RelatoriosGraficoComponent } from './relatorios-grafico/relatorios-grafico.component';
import { ChartsModule } from 'ng2-charts';
import { RelatoriosEstatisticasComponent } from './relatorios-estatisticas/relatorios-estatisticas.component';
import { RelatoriosDinamicoComponent } from './relatorios-dinamico/relatorios-dinamico.component';
import { ImportacaoMensalComponent } from './importacao-mensal/importacao-mensal.component';
import { ImportacoesComponent } from './importacoes/importacoes.component';
import { RegistroatividadesComponent } from './registroatividades/registroatividades.component';
import { SapiemComponent } from './sapiem/sapiem.component';
import { SapiemBeneficiosComponent } from './sapiem/sapiem-beneficios/sapiem-beneficios.component';
import { SapiemBeneficiosDetalheComponent } from './sapiem/sapiem-beneficios/sapiem-beneficios-detalhe/sapiem-beneficios-detalhe.component';
import { SimuladorComponent } from './simulador/simulador.component';
import { PermissoesComponent } from './permissoes/permissoes.component';
import { PermissoesDetalhesComponent } from './permissoes-detalhes/permissoes-detalhes.component';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}

registerLocaleData(pt);


@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, AdminHomeComponent, ServidoresComponent, UsuariosComponent, ServidorComponent, EnderecoComponent, DocumentosComponent, DadosbancariosComponent, AnexosComponent, ContatoComponent, DependentesComponent, MovimentosComponent, TemposComponent, RepresentanteComponent, ObitoComponent, UsuariosMunicipioComponent, OrgaosComponent, RelatoriosMunicipioComponent, RelatoriosAdminComponent, DadospessoaisComponent, ServidorHomeComponent, RelatoriosBaseComponent, RelatoriosPlanilhaComponent, SimuladorAposentadoriaComponent, RelatoriosGraficoComponent, RelatoriosEstatisticasComponent, RelatoriosDinamicoComponent, ImportacaoMensalComponent, ImportacoesComponent, RegistroatividadesComponent, SapiemComponent, SapiemBeneficiosComponent, SapiemBeneficiosDetalheComponent, SimuladorComponent, PermissoesComponent, PermissoesDetalhesComponent],

  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    InternationalPhoneNumberModule,
    SocialLoginModule,  // Place SocialLoginModule in imports
    SharedModule,
    CreditCardDirectivesModule,
    HttpClientModule,
    AngularFireAuthModule,
    ChartsModule,
    YouTubePlayerModule,
    NgxPrintModule,
    NgbModule,
    NgxIntlTelInputModule,
    WebcamModule,
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAP_API_KEY"
    }),
    PerfectScrollbarModule,
    DragulaModule,

    NgSelectModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ArchwizardModule,
    CustomFormsModule,
    InfiniteScrollModule,
    UiSwitchModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
    ReactiveFormsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

    AuthGuard,
    App,
    Api,
    ApiSimulador,
    Me,
    DragulaService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
