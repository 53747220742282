<div class="card" style="border-radius: 10px">
  <div class="card-body">
    <div class="row text-left">
      <div class="col-sm-12">
        <div class="d-flex justify-content-between mb-0">
          <h1 class="text-bold-400" style="color: #4e7977">
            Servidores {{ nomeCidade }}
          </h1>
        </div>
      </div>
      <div class="col-sm-12">
        <p class="mb-2 mt-2" style="font-size: 20px">
          Visualize e gerencie todos os servidores públicos do seu município.
          Utilize a busca e os filtros para encontrar e atualizar informações de
          forma eficiente.
        </p>
      </div>
    </div>

    <form #servForm="ngForm" novalidate>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="nome" style="font-weight: bold">Nome</label>
              <input
                type="text"
                id="nome"
                class="form-control"
                [(ngModel)]="servidor.nome"
                name="nome"
                placeholder="Procure por nome"
                required
                aria-invalid="false"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="cpf" style="font-weight: bold">CPF</label>
              <input
                type="text"
                id="cpf"
                class="form-control"
                [(ngModel)]="servidor.cpf"
                name="cpf"
                placeholder="Digite um cpf"
                required
                aria-invalid="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="matricula" style="font-weight: bold">Matrícula</label>
              <input
                type="text"
                id="matricula"
                class="form-control"
                [(ngModel)]="servidor.matricula"
                name="matricula"
                placeholder="Digíte uma matrícula"
                required
                aria-invalid="false"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="especificidade" style="font-weight: bold"
                >Especificidade</label
              >
              <ng-select
                [items]="estados"
                name="especificidade"
                (change)="toggleEstado()"
                bindLabel="especificidade"
                bindValue="especificidade"
                placeholder="Selecione"
                id="especificidade"
                [(ngModel)]="servidor.estado_naturalidade"
              ></ng-select>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="status" style="font-weight: bold">Status</label>
              <ng-select
                [items]="status"
                name="status"
                (change)="toggleEstado()"
                bindLabel="status"
                bindValue="status"
                placeholder="Selecione"
                id="status"
                [(ngModel)]="servidor.status"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="data_cadastro" style="font-weight: bold"
              >Data de cadastro</label
            >
            <div class="d-flex">
              <div class="input-group">
                <input
                  type="text"
                  id="data_cadastro"
                  class="form-control"
                  mask="00/00/0000"
                  [(ngModel)]="servidor.data_nascimento"
                  name="data_cadastro"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
              <span class="mx-2 d-flex align-items-center">até</span>
              <div class="input-group">
                <input
                  type="text"
                  id="data_nascimento"
                  class="form-control"
                  mask="00/00/0000"
                  [(ngModel)]="servidor.data_nascimento"
                  name="data_nascimento"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="data_nascimento" style="font-weight: bold"
              >Data de nascimento</label
            >
            <div class="d-flex">
              <div class="input-group">
                <input
                  type="text"
                  id="data_nascimento"
                  class="form-control"
                  mask="00/00/0000"
                  [(ngModel)]="servidor.data_nascimento"
                  name="data_nascimento"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
              <span class="mx-2 d-flex align-items-center">até</span>
              <div class="input-group">
                <input
                  type="text"
                  id="data_nascimento"
                  class="form-control"
                  mask="00/00/0000"
                  [(ngModel)]="servidor.data_nascimento"
                  name="data_nascimento"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="form-row d-flex justify-content-end align-items-center">
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="clearFilters()"
        >
          Limpar filtros
        </button>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-primary mb-2 mb-md-0"
          (click)="applyFilters()"
        >
          Pesquisar <i class="fa fa-search mr-1"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="card" style="border-radius: 10px">
  <table class="table text-center" style="padding: 10px">
    <thead>
      <tr>
        <th (click)="sortTable('nome')">
          <div class="d-flex align-items-center justify-content-center">
            Nome
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'nome'" class="fa fa-caret-up"></i>
              <i *ngIf="sortColumn !== 'nome'" class="fa fa-caret-down"></i>
              <i
                *ngIf="sortColumn === 'nome'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th (click)="sortTable('cpf')">
          <div class="d-flex align-items-center justify-content-center">
            CPF
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'cpf'" class="fa fa-caret-up"></i>
              <i *ngIf="sortColumn !== 'cpf'" class="fa fa-caret-down"></i>
              <i
                *ngIf="sortColumn === 'cpf'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th (click)="sortTable('matricula')">
          <div class="d-flex align-items-center justify-content-center">
            Matrícula
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'matricula'" class="fa fa-caret-up"></i>
              <i *ngIf="sortColumn !== 'matricula'" class="fa fa-caret-down"></i>
              <i
                *ngIf="sortColumn === 'matricula'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th (click)="sortTable('status')">
          <div class="d-flex align-items-center justify-content-center">
            Status
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'status'" class="fa fa-caret-up"></i>
              <i *ngIf="sortColumn !== 'status'" class="fa fa-caret-down"></i>
              <i
                *ngIf="sortColumn === 'status'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        [ngStyle]="{ background: getColor(servidor) }"
        *ngFor="
          let servidor of servidoresFiltered
            | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
        "
      >
        <td class="pl-3 pr-3">
          <div
            class="d-flex align-items-center"
            style="padding-left: 10px; padding-right: 10px"
          >
            <img
              width="50"
              height="50"
              alt="datatable-avatar"
              class="square rounded-circle"
              [ngClass]="{ 'cursor-pointer': servidor && servidor.imagem }"
              (click)="
                servidor && servidor.imagem
                  ? viewMidia(servidor.imagem, contentImg)
                  : null
              "
              [src]="
                servidor && servidor.imagem
                  ? servidor.imagem
                  : 'assets/img/portrait/small/no-avatar.svg'
              "
              style="margin-right: 10px"
            />
            <div>{{ servidor.nome }}</div>
          </div>
        </td>
        <td class="pl-0 pr-0 text-center align-middle">{{ servidor.cpf | mask : "000.000.000-00" }}</td>
        <td class="pl-0 pr-0 text-center align-middle">
          {{
            servidor.status == 2 || servidor.status == 3
              ? servidor.matricula_beneficiario
              : servidor.matricula
          }}
        </td>
        <td class="pl-0 pr-0 text-center align-middle">{{ classificaStatus(servidor.status) }}</td>
        <td style="padding: 10px" class="text-center align-middle">
          <div class="btn-group-custom text-center align-middle">
            <button
              type="button"
              class="btn button--visualizar"
              routerLink="/page/sapiem-beneficios"
              [queryParams]="{ id: servidor.id, id_cidade: servidor.id_cidade, cidade: nomeCidade }"
            >
              <i class="fa fa-eye"></i> Benefícios
            </button>
            <button
              type="button"
              class="btn button--visualizar"
              (click)="onGenerateXMLById(servidor.id)"
            >
              <i class="fa fa-copy"></i> XML
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="form-row mt-2 d-flex justify-content-end" style="margin: 5px">
      <ngb-pagination
        class="ml-auto"
        style="margin-top: 10px; margin-right: 10px"
        *ngIf="servidoresFiltered.length > 0"
        [(page)]="page"
        [pageSize]="pageSize"
        [maxSize]="2"
        [rotate]="true"
        [boundaryLinks]="true"
        size="sm"
        [collectionSize]="servidoresFiltered.length"
      ></ngb-pagination>
  </div>
</div>

<ngx-spinner></ngx-spinner>
