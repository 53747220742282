<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <form class="card-body">
          <h3 class="text-bold-300">Endereço</h3>
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cep">CEP*</label>
                    <input
                      type="text"
                      id="cep"
                      class="form-control"
                      [(ngModel)]="endereco.cep"
                      mask="00.000-000"
                      name="cep"
                      placeholder="CEP"
                      (change)="consultaCep()"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tiposlogradouro">Tipo Logradouro*</label>
                    <ng-select
                      [items]="tiposlogradouro"
                      name="tiposlogradouro"
                      bindLabel="descricao"
                      bindValue="id"
                      placeholder="Tipo Logradouro"
                      id="tiposlogradouro"
                      [(ngModel)]="endereco.tipo_logradouro"
                    ></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="logradouro">Logradouro*</label>
                    <input
                      type="text"
                      id="logradouro"
                      class="form-control"
                      [(ngModel)]="endereco.logradouro"
                      name="logradouro"
                      placeholder="Logradouro"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="numero">Número*</label>
                    <input
                      type="text"
                      id="numero"
                      class="form-control"
                      [(ngModel)]="endereco.numero"
                      name="numero"
                      placeholder="Número"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="bairro">Bairro*</label>
                    <input
                      type="text"
                      id="bairro"
                      class="form-control"
                      [(ngModel)]="endereco.bairro"
                      name="bairro"
                      placeholder="Bairro"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="complemento">Complemento*</label>
                    <input
                      type="text"
                      id="complemento"
                      class="form-control"
                      [(ngModel)]="endereco.complemento"
                      name="complemento"
                      placeholder="Complemento"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="estado">Estado*</label>
                    <ng-select
                      [items]="estados"
                      name="estado"
                      (change)="toggleEstado()"
                      bindLabel="estado"
                      bindValue="estado"
                      placeholder="Estado"
                      id="estado"
                      [(ngModel)]="endereco.estado"
                    ></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cidade">Cidade*</label>
                    <ng-select
                      [items]="cidades"
                      [disabled]="!cidades || cidades.length == 0"
                      name="cidade"
                      bindLabel="nome"
                      bindValue="id"
                      placeholder="Cidade"
                      id="cidade"
                      [(ngModel)]="endereco.cidade"
                    ></ng-select>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <h3 class="text-bold-300">Documentos</h3>
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cpf"
                      >CPF*
                      <b
                        *ngIf="
                          servidor && servidor.status && servidor.status == 1
                        "
                        style="color: red"
                        >*</b
                      ></label
                    >
                    <input
                      type="text"
                      id="cpf"
                      class="form-control"
                      [(ngModel)]="documentos.cpf"
                      mask="000.000.000-00"
                      name="cpf"
                      placeholder="CPF"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="rg"
                      >RG*
                      <b
                        *ngIf="
                          servidor && servidor.status && servidor.status == 1
                        "
                        style="color: red"
                        >*</b
                      ></label
                    >
                    <input
                      type="text"
                      id="rg"
                      class="form-control"
                      [(ngModel)]="documentos.rg"
                      name="rg"
                      placeholder="RG"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_emissao_rg">Emissão RG*</label>
                    <input
                      type="text"
                      id="data_emissao_rg"
                      class="form-control"
                      mask="00/00/0000"
                      [(ngModel)]="documentos.data_emissao_rg"
                      name="data_emissao_rg"
                      placeholder="Data Emissão RG"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="orgaosExpedidores"
                      >Orgão Expedidor RG*
                      <b
                        *ngIf="
                          servidor && servidor.status && servidor.status == 1
                        "
                        style="color: red"
                        >*</b
                      ></label
                    >
                    <ng-select
                      [items]="orgaosExpedidores"
                      name="orgaosExpedidores"
                      bindLabel="descricao"
                      bindValue="id"
                      placeholder="Orgão Expedidor RG"
                      id="orgaosExpedidores"
                      [(ngModel)]="documentos.orgao_expedidor_rg"
                    ></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="uf_rg"
                      >UF RG*
                      <b
                        *ngIf="
                          servidor && servidor.status && servidor.status == 1
                        "
                        style="color: red"
                        >*</b
                      ></label
                    >
                    <ng-select
                      [items]="estados"
                      name="uf_rg"
                      bindLabel="estado"
                      bindValue="estado"
                      placeholder="UF RG"
                      id="uf_rg"
                      [(ngModel)]="documentos.uf_rg"
                    ></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nit"
                      >PIS*
                      <b
                        *ngIf="
                          servidor && servidor.status && servidor.status == 1
                        "
                        style="color: red"
                        >*</b
                      ></label
                    >
                    <input
                      type="text"
                      id="nit"
                      class="form-control"
                      [(ngModel)]="documentos.nit"
                      mask="000.00000.00-0"
                      name="nit"
                      placeholder="PIS"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cpts">CPTS</label>
                    <input
                      type="text"
                      id="cpts"
                      class="form-control"
                      [(ngModel)]="documentos.cpts"
                      maxlength="15"
                      name="cpts"
                      placeholder="CPTS"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="serie_cpts">Série CPTS</label>
                    <input
                      type="text"
                      id="serie_cpts"
                      class="form-control"
                      [(ngModel)]="documentos.serie_cpts"
                      name="serie_cpts"
                      mask="0000"
                      placeholder="Série CPTS"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_emissao_cpts">Data Emissão CPTS</label>
                    <input
                      type="text"
                      id="data_emissao_cpts"
                      class="form-control"
                      mask="00/00/0000"
                      [(ngModel)]="documentos.data_emissao_cpts"
                      name="data_emissao_cpts"
                      placeholder="Data Emissão CPTS"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div> -->
            </div>
          </form>
          <h3 class="text-bold-300">Contatos</h3>
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="telefone">Telefone*</label>
                    <input
                      type="text"
                      id="telefone"
                      class="form-control"
                      [(ngModel)]="contato.telefone"
                      mask="(00) 0000-0000"
                      name="telefone"
                      placeholder="Telefone"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="celular">Celular*</label>
                    <input
                      type="text"
                      id="celular"
                      class="form-control"
                      [(ngModel)]="contato.celular"
                      mask="(00) 00000-0000"
                      name="celular"
                      placeholder="Celular"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="email">Email*</label>
                    <input
                      type="text"
                      id="email"
                      class="form-control"
                      [(ngModel)]="contato.email"
                      name="email"
                      placeholder="Email"
                      required
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  routerLink="/page/servidor"
                  [queryParams]="{ id: idServidor }"
                >
                  Voltar
                </button>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                  (click)="atualizarOuCadastrarContato()"
                  [disabled]="
                  !endereco.cep ||
                  !endereco.tipo_logradouro ||
                  !endereco.logradouro ||
                  !endereco.bairro ||
                  !endereco.complemento ||
                  !endereco.cidade ||
                  !endereco.estado ||
                  !endereco.numero || disableDocumentos() ||
                    (!contato.celular && !contato.email && !contato.telefone)
                  "
                >
                  Salvar
                </button>
              </div>
            </div>
            <small>Campos marcados com asterisco (*) são obrigatórios</small>
          </form>
        </form>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
