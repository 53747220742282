<div class="container">
  <h2 class="mb-4">{{ nomeServidor ? nomeServidor : "" }} > {{ nomeBeneficio ? nomeBeneficio : ""}}</h2>
  <div class="benefit-data">
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h4>
          Informações Básicas do Benefício
          <i
            class="fa fa-check-circle text-success"
            aria-hidden="true"
            *ngIf="basicInfoValid"
          ></i>
          <i
            class="fa fa-exclamation-triangle text-warning"
            aria-hidden="true"
            *ngIf="!basicInfoValid"
          ></i>
        </h4>
        <button class="btn-no-border" (click)="toggleBasicInfo()">
          {{ basicInfoVisible ? "Ocultar" : "Mostrar" }}
        </button>
      </div>

      <div class="card-body" *ngIf="basicInfoVisible">
        <form [formGroup]="informacoesBeneficioForm">
          <!-- Informações Básicas -->
          <h5>Informações Básicas</h5>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="generoBeneficio">Gênero do Benefício</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="generoBeneficio"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Gênero Benefício"
                  id="generoBeneficio"
                  formControlName="generoBeneficio"
                ></ng-select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="especie">Espécie</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="especieBeneficio"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Especie Benefício"
                  id="especieBeneficio"
                  formControlName="especieBeneficio"
                ></ng-select>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="generoBeneficio">Modalidade do Benefício</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="modalidadeBeneficio"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Modalidade"
                  id="modalidadeBeneficio"
                  formControlName="modalidadeBeneficio"
                ></ng-select>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="matricula">Matrícula</label>
                <input
                  type="number"
                  id="matricula"
                  class="form-control"
                  formControlName="matricula"
                  placeholder="Insira a matrícula"
                  min="0"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="identificacaoFuncional"
                  >Identificação Funcional</label
                >
                <input
                  type="text"
                  id="identificacaoFuncional"
                  class="form-control"
                  formControlName="identificacaoFuncional"
                  placeholder="Insira a identificação funcional"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="cargo">Cargo</label>
                <input
                  type="text"
                  id="cargo"
                  class="form-control"
                  formControlName="cargo"
                  placeholder="Insira o cargo"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="regimeJuridico">Regime Jurídico</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="regimeJuridico"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Modalidade"
                  id="regimeJuridico"
                  formControlName="regimeJuridico"
                ></ng-select>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="cargaHoraria">Carga Horária</label>
                <input
                  type="number"
                  id="cargaHoraria"
                  class="form-control"
                  formControlName="cargaHoraria"
                  placeholder="Insira a carga horária"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="padrao">Padrão</label>
                <input
                  type="text"
                  id="padrao"
                  class="form-control"
                  formControlName="padrao"
                  placeholder="Insira o padrão"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="nivel">Nível</label>
                <input
                  type="text"
                  id="nivel"
                  class="form-control"
                  formControlName="nivel"
                  placeholder="Insira o nível"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="classe">Classe</label>
                <input
                  type="text"
                  id="classe"
                  class="form-control"
                  formControlName="classe"
                  placeholder="Insira a classe"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="outros">Outros</label>
                <input
                  type="text"
                  id="outros"
                  class="form-control"
                  formControlName="outros"
                  placeholder="Informações adicionais"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="escolaridade">Escolaridade</label>
                <input
                  type="text"
                  id="escolaridade"
                  class="form-control"
                  formControlName="escolaridade"
                  placeholder="Insira a escolaridade"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="observacoes">Observações</label>
                <input
                  type="text"
                  id="observacoes"
                  class="form-control"
                  formControlName="observacoes"
                  placeholder="Insira observações"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="tituloEleitor">Título de Eleitor</label>
                <input
                  type="text"
                  id="tituloEleitor"
                  class="form-control"
                  formControlName="tituloEleitor"
                  placeholder="Insira o título de eleitor"
                  mask="000000000000"
                />
              </div>
            </div>
          </div>

          <hr />

          <!-- Informações Legislativas -->
          <h5 class="mt-4">Informações Legislativas</h5>
          <div class="form-row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="codigoLegislativo">Código</label>
                <input
                  type="text"
                  id="codigoLegislativo"
                  class="form-control"
                  formControlName="codigoLegislativo"
                  placeholder="Insira o código"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="cpfResponsavel">CPF Responsável</label>
                <input
                  type="text"
                  id="cpfResponsavel"
                  class="form-control"
                  formControlName="cpfResponsavel"
                  placeholder="CPF Responsável"
                  mask="000.000.000-00"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="cpfSignatario">CPF Signatário</label>
                <input
                  type="text"
                  id="cpfSignatario"
                  class="form-control"
                  formControlName="cpfSignatario"
                  placeholder="CPF Signatário"
                  mask="000.000.000-00"
                />
              </div>
            </div>
          </div>

          <hr />

          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="tipoLegislacao">Tipo da Legislação</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoLegislacao"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Tipo Legislação"
                  id="tipoLegislacao"
                  formControlName="tipoLegislacao"
                ></ng-select>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="numeroLegislacao">Número da Legislação</label>
                <input
                  type="text"
                  id="numeroLegislacao"
                  class="form-control"
                  formControlName="numeroLegislacao"
                  placeholder="Insira o número da legislação"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="anoLegislacao">Ano da Legislação</label>
                <input
                  type="text"
                  id="anoLegislacao"
                  class="form-control"
                  formControlName="anoLegislacao"
                  placeholder="Ano da legislação"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="dataInicio">Data de Início</label>
                <input
                  type="date"
                  id="dataInicio"
                  class="form-control"
                  formControlName="dataInicio"
                  placeholder="dd-mm-yyyy"
                />
              </div>
            </div>
          </div>

          <hr />

          <!-- Informações Salariais -->
          <h5 class="mt-4">Informações Salariais</h5>
          <div class="form-row align-items-end">
            <div class="col-md-6">
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-block btn-outline-primary"
                  (click)="importar()"
                >
                  <i class="fa fa-upload"></i> Carregar Planilha
                </button>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="mediaSalarial">Média Salarial</label>
                <input
                  type="text"
                  id="mediaSalarial"
                  class="form-control"
                  formControlName="mediaSalarial"
                  placeholder="Insira a média salarial"
                  mask="separator.2"
                  prefix="R$ "
                  thousandSeparator="."
                  decimalMarker=","
                  [dropSpecialCharacters]="false"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="dataDemonstrativo">Data do Demonstrativo</label>
                <input
                  type="date"
                  id="dataDemonstrativo"
                  class="form-control"
                  formControlName="dataDemonstrativo"
                  placeholder="dd-mm-yyyy"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="assinadoPor">Assinado Por</label>
                <input
                  type="text"
                  id="assinadoPor"
                  class="form-control"
                  formControlName="assinadoPor"
                  placeholder="Assinado por"
                />
              </div>
            </div>
          </div>

          <!-- Informações Judiciais -->
          <h5 class="mt-4">Informações Judiciais</h5>
          <div class="form-row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="tipoAto">Tipo Ato</label>
                <input
                  type="text"
                  id="tipoAto"
                  class="form-control"
                  formControlName="tipoAto"
                  placeholder="Insira o tipo de ato"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="numeroAto">Número do Ato</label>
                <input
                  type="text"
                  id="numeroAto"
                  class="form-control"
                  formControlName="numeroAto"
                  placeholder="Insira o número do ato"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="anoAto">Ano do Ato</label>
                <input
                  type="text"
                  id="anoAto"
                  class="form-control"
                  formControlName="anoAto"
                  placeholder="Insira o ano do ato"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="valorProvento">Valor do Provento</label>
                <input
                  type="text"
                  id="valorProvento"
                  class="form-control"
                  formControlName="valorProvento"
                  placeholder="Insira o valor do provento"
                  mask="separator.2"
                  prefix="R$ "
                  thousandSeparator="."
                  decimalMarker=","
                  [dropSpecialCharacters]="false"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="vantagens">Vantagens</label>
                <input
                  type="text"
                  id="vantagens"
                  class="form-control"
                  formControlName="vantagens"
                  placeholder="Insira as vantagens"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="descricaoOrgao">Complemento Salarial</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Complemento"
                  id="complementoSalarial"
                  formControlName="complementoSalarial"
                ></ng-select>
              </div>
            </div>
          </div>
            <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="descricaoOrgao">Descrição do Órgão Custeador</label>
                <input
                  type="text"
                  id="descricaoOrgao"
                  class="form-control"
                  formControlName="descricaoOrgao"
                  placeholder="Insira a descrição do órgão"
                />
              </div>
            </div>
          </div>

          <hr />

          <!-- Informações Judiciais -->
          <h5 class="mt-4">Informações Aposentadoria</h5>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="descricaoOrgao">Fundamento Aposentadoria</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Complemento"
                  id="fundamentoAposentadoria"
                  formControlName="fundamentoAposentadoria"
                ></ng-select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="dataRequerimento">Data do Requerimento</label>
                <input
                  type="date"
                  id="dataRequerimento"
                  class="form-control"
                  formControlName="dataRequerimento"
                  placeholder="dd-mm-yyyy"
                />
              </div>
            </div>
          </div>

          <!-- Botões de ação -->
          <div class="form-row">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                (click)="onSubmitBaseBenefit()"
                [disabled]="informacoesBeneficioForm.invalid"
              >
                Atualizar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h4>
          Composição de Remuneração
          <i
            class="fa fa-check-circle text-success"
            aria-hidden="true"
            *ngIf="compRemuneracaoValid"
          ></i>
          <i
            class="fa fa-exclamation-triangle text-warning"
            aria-hidden="true"
            *ngIf="!compRemuneracaoValid"
          ></i>
        </h4>
        <button class="btn-no-border" (click)="toggleCompRemuneracao()">
          {{ compRemuneracaoVisible ? "Ocultar" : "Mostrar" }}
        </button>
      </div>

      <div class="card-body" *ngIf="compRemuneracaoVisible">
        <!-- Estado: Sem Benefícios -->
        <div
          *ngIf="remuneracoes && remuneracoes.length === 0"
          class="no-benefits"
        >
          <!-- Alerta -->
          <div class="alert alert-warning mb-3" role="alert">
            <i class="fa fa-exclamation-triangle"></i> É necessário ao menos um
            item cadastrado.
          </div>
          <!-- Mensagem de orientação -->
          <p class="text-muted text-center">
            Adicione um novo item clicando no botão "Adicionar"
          </p>
        </div>
        <div
          *ngFor="let remuneracao of remuneracoes; let i = index"
          [ngClass]="{
            'custom-card': true,
            'single-card': remuneracoes.length === 1,
            'mt-0': i !== 0,
            'first-card': i === 0,
            'last-card': i === remuneracoes.length - 1
          }"
        >
          <div class="card-body">
            <!-- Título -->
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title text-start">
                {{ remuneracao.descricao }}
              </h5>
            </div>

            <div class="row">
              <!-- Informações da Remuneração -->
              <div class="col-8 card-text mt-3">
                <p>
                  <strong>Valor:</strong>
                  {{ formatToBRLCurrency(remuneracao.valor) }}
                </p>
                <p>
                  <strong>Legislação:</strong>
                  {{ getLegislacaoNome(remuneracao.tipo_legislacao) }}
                </p>
              </div>

              <!-- Botões de Ação -->
              <div
                class="col-4 actions d-flex justify-content-end align-items-center"
              >
                <button
                  class="btn btn-outline-danger"
                  (click)="deleteRemuneracao(remuneracao.id)"
                >
                  <i class="fa fa-trash"></i>
                </button>
                <button
                  class="btn btn-outline-success"
                  (click)="editRemuneracao(remuneracao, cadastroRemuneracao)"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end align-items-end mb-3 mt-3">
          <button
            class="btn btn-outline-primary"
            (click)="openModal(cadastroRemuneracao)"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>

    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h4>
          Registro de Assentamento
          <i
            class="fa fa-check-circle text-success"
            aria-hidden="true"
            *ngIf="regAssentamentoValid"
          ></i>
          <i
            class="fa fa-exclamation-triangle text-warning"
            aria-hidden="true"
            *ngIf="!regAssentamentoValid"
          ></i>
        </h4>
        <button class="btn-no-border" (click)="toggleRegAssentamento()">
          {{ regAssentamentoVisible ? "Ocultar" : "Mostrar" }}
        </button>
      </div>

      <div class="card-body" *ngIf="regAssentamentoVisible">
        <!-- Estado: Sem Benefícios -->
        <div
          *ngIf="assentamentos && assentamentos.length === 0"
          class="no-benefits"
        >
          <!-- Alerta -->
          <div class="alert alert-warning mb-3" role="alert">
            <i class="fa fa-exclamation-triangle"></i> É necessário ao menos um
            item cadastrado.
          </div>
          <!-- Mensagem de orientação -->
          <p class="text-muted text-center">
            Adicione um novo item clicando no botão "Adicionar"
          </p>
        </div>

        <div
          *ngFor="let assentamento of assentamentos; let i = index"
          [ngClass]="{
            'custom-card': true,
            'single-card': assentamentos.length === 1,
            'mt-0': i !== 0,
            'first-card': i === 0,
            'last-card': i === assentamentos.length - 1
          }"
        >
          <div class="card-body">
            <!-- Título -->
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title text-start">Tipo de Assentamento</h5>
            </div>

            <div class="row">
              <!-- Informações do Assentamento -->
              <div class="col-8 card-text mt-3">
                <p>
                  <strong>Legislação:</strong>
                  {{ getLegislacaoNome(assentamento.tipo_legislacao) }}
                </p>
              </div>

              <!-- Botões de Ação -->
              <div
                class="col-4 actions d-flex justify-content-end align-items-center"
              >
                <button
                  class="btn btn-outline-danger"
                  (click)="deleteAssentamento(assentamento.id)"
                >
                  <i class="fa fa-trash"></i>
                </button>
                <button
                  class="btn btn-outline-success"
                  (click)="editAssentamento(assentamento, cadastroAssentamento)"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end align-items-end mb-3 mt-3">
          <button
            class="btn btn-outline-primary"
            (click)="openModal(cadastroAssentamento)"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>

    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h4>
          Exercício de Magistério
          <i
            class="fa fa-check-circle text-success"
            aria-hidden="true"
            *ngIf="validateAnothers"
          ></i>
          <i
            class="fa fa-exclamation-triangle text-warning"
            aria-hidden="true"
            *ngIf="validateAnothers"
          ></i>
        </h4>
        <button class="btn-no-border" (click)="toggleExercMagisterio()">
          {{ exercMagisterioVisible ? "Ocultar" : "Mostrar" }}
        </button>
      </div>

      <div class="card-body" *ngIf="exercMagisterioVisible">
        <!-- Estado: Sem Benefícios -->
        <div
          *ngIf="exerciciosMagisterio && exerciciosMagisterio.length === 0"
          class="no-benefits"
        >
          <!-- Mensagem de orientação -->
          <p class="text-muted text-center">
            Adicione um novo item clicando no botão "Adicionar"
          </p>
        </div>

        

        <div *ngFor="let exercicio of exerciciosMagisterio; let i = index"
        [ngClass]="{
          'custom-card': true,
          'single-card': exerciciosMagisterio.length === 1,
          'mt-0': i !== 0, 
          'first-card': i === 0,
          'last-card': i === exerciciosMagisterio.length - 1
        }">
     <div class="card-body">
       <!-- Título -->
       <div class="d-flex justify-content-between align-items-center">
         <h5 class="card-title text-start">
           Exercicio de Magistério
         </h5>
       </div>
   
       <div class="row">
         <!-- Informações do Exercício -->
         <div class="col-8 card-text mt-3">
           <p>
             <strong>Nome da Escola:</strong> {{ exercicio.nome_escola }}
           </p>
           <p>
             <strong>Descrição da Atividade:</strong>
             {{ exercicio.descricao_atividade }}
           </p>
           <p>
             <strong>Data Início:</strong>
             {{ exercicio.data_inicio | date : "dd/MM/yyyy" }}
           </p>
           <p>
             <strong>Data Fim:</strong>
             {{ exercicio.data_fim | date : "dd/MM/yyyy" }}
           </p>
         </div>
   
         <!-- Botões de Ação -->
         <div class="col-4 actions d-flex justify-content-end align-items-center">
           <button
             class="btn btn-outline-danger"
             (click)="deleteExercicioMagisterio(exercicio.id)"
           >
             <i class="fa fa-trash"></i>
           </button>
           <button
             class="btn btn-outline-success"
             (click)="editExercicioMagisterio(exercicio, cadastroExercicioMagisterio)"
           >
             <i class="fa fa-edit"></i>
           </button>
         </div>
       </div>
     </div>
   </div>
   

        <div class="d-flex justify-content-end align-items-end mb-3 mt-3">
          <button
            class="btn btn-outline-primary"
            (click)="openModal(cadastroExercicioMagisterio)"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>

    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h4>
          Registro de Averbação
          <i
            class="fa fa-check-circle text-success"
            aria-hidden="true"
            *ngIf="validateAnothers"
          ></i>
          <i
            class="fa fa-exclamation-triangle text-warning"
            aria-hidden="true"
            *ngIf="validateAnothers"
          ></i>
        </h4>
        <button class="btn-no-border" (click)="toggleRegAverbacao()">
          {{ regAverbacaoVisible ? "Ocultar" : "Mostrar" }}
        </button>
      </div>

      <div class="card-body" *ngIf="regAverbacaoVisible">
        <!-- Estado: Sem Benefícios -->
        <div *ngIf="averbacoes && averbacoes.length === 0" class="no-benefits">
          <!-- Mensagem de orientação -->
          <p class="text-muted text-center">
            Adicione um novo item clicando no botão "Adicionar"
          </p>
        </div>

        
        <div *ngFor="let averbacao of averbacoes; let i = index"
        [ngClass]="{
          'custom-card': true,
          'single-card': averbacoes.length === 1,
          'mt-0': i !== 0, 
          'first-card': i === 0,
          'last-card': i === averbacoes.length - 1
        }">
     <div class="card-body">
       <!-- Título -->
       <div class="d-flex justify-content-between align-items-center">
         <h5 class="card-title text-start">
           Averbação
         </h5>
       </div>
   
       <div class="row">
         <!-- Informações da Averbação -->
         <div class="col-8 card-text mt-3">
           <p>
             <strong>Descrição:</strong> {{ averbacao.descricao }}
           </p>
           <p>
             <strong>Data Início:</strong>
             {{ averbacao.data_inicio | date : "dd/MM/yyyy" }}
           </p>
           <p>
             <strong>Data Fim:</strong>
             {{ averbacao.data_fim | date : "dd/MM/yyyy" }}
           </p>
         </div>
   
         <!-- Botões de Ação -->
         <div class="col-4 actions d-flex justify-content-end align-items-center">
           <button
             class="btn btn-outline-danger"
             (click)="deleteAverbacao(averbacao.id)"
           >
             <i class="fa fa-trash"></i>
           </button>
           <button
             class="btn btn-outline-success"
             (click)="editAverbacao(averbacao, cadastroAverbacao)"
           >
             <i class="fa fa-edit"></i>
           </button>
         </div>
       </div>
     </div>
   </div>
   

        <div class="d-flex justify-content-end align-items-end mb-3">
          <button
            class="btn btn-outline-primary"
            (click)="openModal(cadastroExercicioMagisterio)"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="btn btn-outline-primary" (click)="previousPage()">
        Voltar
      </button>
      <button class="btn btn-primary" (click)="onSubmitBaseBenefit()">
        Salvar
      </button>
    </div>

    <ng-template #cadastroAssentamento let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Cadastro de Assentamento</h5>
        <button
          type="button"
          class="btn btn-outline-secondary"
          aria-label="Close"
          (click)="onCancelAssentamento(modal)"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="assentamentoForm"
          (ngSubmit)="onSubmitAssentamento()"
        >
          <div class="row">
            <!-- Tipo de Assentamento -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="tipoAssentamento">Tipo de Assentamento</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoAssentamento"
                  bindLabel="descricao"
                  bindValue="id"
                  placeholder="Selecione o tipo de assentamento"
                  id="tipoAssentamento"
                  formControlName="tipo_assentamento"
                >
                </ng-select>
              </div>
            </div>

            <!-- Data -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="data">Data</label>
                <input
                  type="date"
                  id="data"
                  class="form-control"
                  formControlName="data"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Tipo da Legislação -->
            <div class="col-6">
              <div class="form-group">
                <label for="tipoLegislacao">Tipo da Legislação</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoLegislacao"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione o tipo da legislação"
                  id="tipoLegislacao"
                  formControlName="tipo_legislacao"
                >
                </ng-select>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="numeroLegislacao">Número Legislação</label>
                <input
                  type="text"
                  id="numeroLegislacao"
                  class="form-control"
                  formControlName="numero_legislacao"
                  placeholder="Insira o número da legislação"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Ano da Legislação -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="anoLegislacao">Ano da Legislação</label>
                <input
                  type="number"
                  id="anoLegislacao"
                  class="form-control"
                  formControlName="ano_legislacao"
                  placeholder="Insira o ano da legislação"
                />
              </div>
            </div>

            <!-- Número do Ato -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="numeroAto">Número do Ato</label>
                <input
                  type="text"
                  id="numeroAto"
                  class="form-control"
                  formControlName="numero_ato"
                  placeholder="Insira o número do ato"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Data de Publicação -->
            <div class="col-md-4 col-12">
              <div class="form-group">
                <label for="dataPublicacao">Data de Publicação</label>
                <input
                  type="date"
                  id="dataPublicacao"
                  class="form-control"
                  formControlName="data_publicacao"
                />
              </div>
            </div>

            <!-- Número de Dias -->
            <div class="col-md-4 col-12">
              <div class="form-group">
                <label for="numeroDias">Número de Dias</label>
                <input
                  type="number"
                  id="numeroDias"
                  class="form-control"
                  formControlName="numero_dias"
                  placeholder="Insira o número de dias"
                />
              </div>
            </div>

            <!-- Converter em Dobro -->
            <div class="col-md-4 col-12">
              <div class="form-group">
                <label for="diasConvertidosEmDobro"
                  >Converter Dias em Dobro</label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione"
                  id="diasConvertidosEmDobro"
                  formControlName="dias_convertidos_em_dobro"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Descrição -->
            <div class="col-12">
              <div class="form-group">
                <label for="descricao">Descrição</label>
                <textarea
                  id="descricao"
                  class="form-control"
                  formControlName="descricao"
                  rows="3"
                  placeholder="Insira a descrição"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Início do Período Aquisitivo -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="dataInicioPeriodoAquisitivo"
                  >Início do Período Aquisitivo</label
                >
                <input
                  type="date"
                  id="dataInicioPeriodoAquisitivo"
                  class="form-control"
                  formControlName="data_inicio_periodo_aquisitivo"
                />
              </div>
            </div>

            <!-- Fim do Período Aquisitivo -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="dataFimPeriodoAquisitivo"
                  >Fim do Período Aquisitivo</label
                >
                <input
                  type="date"
                  id="dataFimPeriodoAquisitivo"
                  class="form-control"
                  formControlName="data_fim_periodo_aquisitivo"
                />
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-between">
            <!-- Botão Voltar -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="onCancelAssentamento(modal)"
            >
              Voltar
            </button>
            <!-- Botão Salvar -->
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="assentamentoForm.invalid"
            >
              {{ assentamentoForm.value.id ? "Atualizar" : "Cadastrar" }}
            </button>
          </div>
        </form>
      </div>
    </ng-template>

    <ng-template #cadastroExercicioMagisterio let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Cadastro de Exercício Magistério</h5>
        <button
          type="button"
          class="btn btn-outline-secondary"
          aria-label="Close"
          (click)="onCancelExercicioMagisterio(modal)"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="exercicioMagisterioForm"
          (ngSubmit)="onSubmitExercicioMagisterio()"
        >
          <div class="row">
            <!-- Nome da Escola -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="nomeEscola">Nome da Escola</label>
                <input
                  type="text"
                  id="nomeEscola"
                  class="form-control"
                  formControlName="nome_escola"
                  placeholder="Insira o nome da escola"
                />
              </div>
            </div>

            <!-- Origem da Escola -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="origemEscola">Origem da Escola</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="origemEscola"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione a origem da escola"
                  id="origemEscola"
                  formControlName="origem_escola"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Tipo da Atividade -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="tipoAtividade">Tipo de Atividade</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoAtividade"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione o tipo de atividade"
                  id="tipoAtividade"
                  formControlName="tipo_atividade"
                >
                </ng-select>
              </div>
            </div>

            <!-- Número da Certidão -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="numeroCertidao">Número da Certidão</label>
                <input
                  type="text"
                  id="numeroCertidao"
                  class="form-control"
                  formControlName="numero_certidao"
                  placeholder="Insira o número da certidão"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Data Início -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="dataInicio">Data Início</label>
                <input
                  type="date"
                  id="dataInicio"
                  class="form-control"
                  formControlName="data_inicio"
                />
              </div>
            </div>

            <!-- Data Fim -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="dataFim">Data Fim</label>
                <input
                  type="date"
                  id="dataFim"
                  class="form-control"
                  formControlName="data_fim"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Data de Requerimento -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="dataRequerimento">Data de Requerimento</label>
                <input
                  type="date"
                  id="dataRequerimento"
                  class="form-control"
                  formControlName="data_requerimento"
                />
              </div>
            </div>

            <!-- Tipo de Escolha -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="tipoEscolha">Fundamentação</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione"
                  id="fundamentacao"
                  formControlName="fundamentacao"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <!-- Descrição da Atividade -->
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="descricaoAtividade">Descrição da Atividade</label>
                <textarea
                  id="descricaoAtividade"
                  class="form-control"
                  formControlName="descricao_atividade"
                  rows="3"
                  placeholder="Insira a descrição da atividade"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="onCancelExercicioMagisterio(modal)"
            >
              Voltar
            </button>
            <!-- Botão Salvar -->
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="exercicioMagisterioForm.invalid"
            >
              {{ exercicioMagisterioForm.value.id ? "Atualizar" : "Cadastrar" }}
            </button>
          </div>
        </form>
      </div>
    </ng-template>

    <ng-template #cadastroAverbacao let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Cadastro de Averbação</h5>
        <button
          type="button"
          class="btn btn-outline-secondary"
          aria-label="Close"
          (click)="onCancelAverbacao(modal)"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="averbacaoForm" (ngSubmit)="onSubmitAverbacao()">
          <div class="row">
            <!-- Descrição -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="descricao">Descrição</label>
                <input
                  type="text"
                  id="descricao"
                  class="form-control"
                  formControlName="descricao"
                  placeholder="Insira a descrição"
                />
              </div>
            </div>

            <!-- Data Início -->
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="dataInicio">Data Início</label>
                <input
                  type="date"
                  id="dataInicio"
                  class="form-control"
                  formControlName="data_inicio"
                />
              </div>
            </div>

            <!-- Data Fim -->
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="dataFim">Data Fim</label>
                <input
                  type="date"
                  id="dataFim"
                  class="form-control"
                  formControlName="data_fim"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Dias Averbados -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="diasAverbados">Dias Averbados</label>
                <input
                  type="number"
                  id="diasAverbados"
                  class="form-control"
                  formControlName="dias_averbados"
                  placeholder="Insira os dias averbados"
                />
              </div>
            </div>

            <!-- Número do Documento -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="numeroDocumento">Número do Documento</label>
                <input
                  type="text"
                  id="numeroDocumento"
                  class="form-control"
                  formControlName="numero_documento"
                  placeholder="Insira o número do documento"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Serviço Público -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="servicoPublico">Serviço Público</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione"
                  id="servicoPublico"
                  formControlName="servico_publico"
                >
                </ng-select>
              </div>
            </div>

            <!-- Carreira -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="carreira">Carreira</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione"
                  id="carreira"
                  formControlName="carreira"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Vantagens -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="vantagens">Vantagens</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione"
                  id="vantagens"
                  formControlName="vantagens"
                >
                </ng-select>
              </div>
            </div>

            <!-- Contribuição INSS -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="contribuicaoINSS">Contribuição INSS</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoEscolha"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione"
                  id="contribuicaoINSS"
                  formControlName="contribuicao_inss"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <!-- Botões de Ação -->
          <div class="modal-footer d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="onCancelAverbacao(modal)"
            >
              Voltar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="averbacaoForm.invalid"
            >
              {{ averbacaoForm.value.id ? "Atualizar" : "Cadastrar" }}
            </button>
          </div>
        </form>
      </div>
    </ng-template>

    <ng-template #cadastroRemuneracao let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Cadastro de Remuneração</h5>
        <button
          type="button"
          class="btn btn-outline-secondary"
          aria-label="Close"
          (click)="onCancelRemuneracao(modal)"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="remuneracaoForm" (ngSubmit)="onSubmitRemuneracao()">
          <!-- Primeira Row: Descrição e Valor -->
          <div class="row">
            <!-- Descrição -->
            <div class="col-md-6 col-12">
              <div class="form-group mb-3">
                <label for="descricao">Descrição</label>
                <input
                  type="text"
                  id="descricao"
                  class="form-control"
                  formControlName="descricao"
                  placeholder="Insira a descrição"
                  required
                />
              </div>
            </div>

            <!-- Valor -->
            <div class="col-md-6 col-12">
              <div class="form-group mb-3">
                <label for="valor">Valor</label>
                <input
                  type="text"
                  id="valor"
                  class="form-control"
                  formControlName="valor"
                  placeholder="Insira o valor"
                  mask="separator.2"
                  prefix="R$ "
                  thousandSeparator="."
                  decimalMarker=","
                  [dropSpecialCharacters]="false"
                />
              </div>
            </div>
          </div>

          <!-- Segunda Row: Tipo de Legislação -->
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-3">
                <label for="tipoLegislacao">Tipo de Legislação</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tipoLegislacao"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Selecione o tipo de legislação"
                  id="tipoLegislacao"
                  formControlName="tipo_legislacao"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <!-- Terceira Row: Número e Ano da Legislação -->
          <div class="row">
            <!-- Número da Legislação -->
            <div class="col-md-6 col-12">
              <div class="form-group mb-3">
                <label for="numeroLegislacao">Número da Legislação</label>
                <input
                  type="text"
                  id="numeroLegislacao"
                  class="form-control"
                  formControlName="numero_legislacao"
                  placeholder="Insira o número da legislação"
                  required
                />
              </div>
            </div>

            <!-- Ano da Legislação -->
            <div class="col-md-6 col-12">
              <div class="form-group mb-3">
                <label for="anoLegislacao">Ano da Legislação</label>
                <input
                  type="number"
                  id="anoLegislacao"
                  class="form-control"
                  formControlName="ano_legislacao"
                  placeholder="Insira o ano da legislação"
                  required
                />
              </div>
            </div>
          </div>

          <!-- Quarta Row: Artigos -->
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-3">
                <label for="artigos">Artigos</label>
                <textarea
                  id="artigos"
                  class="form-control"
                  formControlName="artigos"
                  rows="3"
                  placeholder="Insira os artigos relacionados"
                ></textarea>
              </div>
            </div>
          </div>

          <!-- Botões de Ação -->
          <div class="modal-footer d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="onCancelRemuneracao(modal)"
            >
              Voltar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="remuneracaoForm.invalid"
            >
              {{ remuneracaoForm.value.id ? "Atualizar" : "Cadastrar" }}
            </button>
          </div>
        </form>
      </div>
    </ng-template>

    <ngx-spinner></ngx-spinner>
  </div>
</div>
<ngx-spinner></ngx-spinner>
