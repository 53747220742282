import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ADMINROUTES: RouteInfo[] = [
  {
    path: '/page/admin', title: 'Início', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/usuarios', title: 'Usuários', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/registroatividades', title: 'Registro de Atividades', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/relatorios', title: 'Relatórios', icon: 'ft-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/sapiem', title: 'Sapiem', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/permissoes', title: 'Permissoes', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
];
/*
{
  path: '', title: 'Treinos', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  submenu: [
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
]
},*/
